@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
	font-family: 'Lato';
	src: url('../fonts/lato-regular-webfont.woff2') format('woff2'),
		url('../fonts/lato-regular-webfont.woff') format('woff');
	font-weight: 400;
	font-weight: 'normal' 'regular';
	font-style: normal;
}

@font-face {
	font-family: 'Lato';
	src: url('../fonts/lato-bold-webfont.woff2') format('woff2'),
		url('../fonts/lato-bold-webfont.woff') format('woff');
	font-weight: 500 700;
	font-weight: 'medium' 'bold';
	font-style: normal;
}

@font-face {
	font-family: 'Lato';
	src: url('../fonts/lato-black-webfont.woff2') format('woff2'),
		url('../fonts/lato-black-webfont.woff') format('woff');
	font-weight: 800 900;
	font-weight: 'black' 'heavy';
	font-style: normal;
}

html {
	font-family: 'Lato';
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.Toastify__toast,
.Toastify__toast-body {
	padding: 0 !important;
	min-height: 40px !important;
}

.Toastify__toast-body {
	margin: 0 !important;
}
